import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi..Sadly nothing is here yet.</h1>
    <p>
      You have a cool business idea ? You feel this domain name will do the
      magic?
    </p>
    <p> You are not alone. We also think so.</p>
    <p>We will love to listen to your ideas</p>
    <div
      style={{
        maxWidth: `300px`,
        margin: "auto",
      }}
    >
      <Image />
      <p>
        Let's engage. Shoot us an email
        {` `}
        <a
          href="mailto:thegisdev@gmail.com?Subject=Interest%20in%20Offers.co.ke"
          target="_blank"
          rel="noopener noreferrer"
        >
          thegisdev@gmail.com
        </a>
      </p>
    </div>
  </Layout>
)

export default IndexPage
